import React from 'react';
import './AdditionalContent.css';
import ast1 from './assetts/ast1.png'; // Importing the images
import ast2 from './assetts/ast2.png'; 
import ast3 from './assetts/ast3.png'; 
import roadmapImage from './pgroadmap.png';
import { FaBook, FaPencilAlt, FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const AdditionalContent = () => {
  return (
    <div>
      {/* Promo Section */}
      <div className="promo-section-pg">
      <img src={roadmapImage} alt="Roadmap" className="roadmap-image-pg" />
        <div className="promo-content-pg">
      
          <h1>
          <FaBook /> Learn  <FaPencilAlt /> Practice <FaCheckCircle /> Apply
          </h1>
         
          <div className="promo-buttons-pg">
          <Link to="/python-guide">
  <button className="promo-button-pg demo-button-pg">Free Learning</button>
</Link>
<Link to="/javascript-projects">
  <button className="promo-button-pg get-started-button-pg">Build Projects</button>
</Link>
          </div>
        </div>
      </div>

      {/* Additional Content Section */}
      <div className="additional-content-ac">
        <div className="content-item-ac">
          <img src={ast1} alt="Fastest Way To Learn" className="content-image-ac" />
          <div className="content-text-ac">
            <h2><FaBook />Learn</h2>
            <h3> Dive Deep into Expert-Curated Programming Modules</h3>
            <p>Immerse yourself in meticulously crafted programming modules tailored for each language. Our comprehensive written guides and tutorials will build a solid foundation and advance your knowledge, setting you up for success..</p>
          </div>
        </div>
        <div className="content-item-ac">
          <div className="content-text-ac">
             <h2><FaPencilAlt />Practice</h2>
            <h3> Build Real-World Projects and Tackle Daily Challenges</h3>
            <p> Practice what you’ve learned by working on practical projects and solving daily coding challenges. This hands-on experience will reinforce your skills and prepare you for real-world scenarios, helping you grow as a proficient coder.</p>
          </div>
          <img src={ast2} alt="Easiest Way To Learn" className="content-image-ac" />
        </div>
        <div className="content-item-ac">
          <img src={ast3} alt="Most Addictive Way To Learn" className="content-image-ac" />
          <div className="content-text-ac">
            <h2><FaCheckCircle />Apply</h2>
            <h3> Excel in Your Career with Real-World Interview Questions”</h3>
            <p>Sharpen your coding expertise by tackling actual interview questions from top tech companies. Our curated set of real-world problems will help you prepare effectively for interviews and stand out in the competitive job market..</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalContent;