import React, { Component, Suspense, lazy } from 'react';  
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import './App.css';
import Footer from './Footer';
import HeaderPages from './HeaderPages';  // Import the HeaderPages component
import AdditionalContent from './AdditionalContent';
import { FaSpinner } from 'react-icons/fa';  // Import the spinner icon

// Lazy load the components
const Customers = lazy(() => import('./Customers'));
const CustomerDetails = lazy(() => import('./CustomerDetails'));
const JavaGuide = lazy(() => import('./programming-guide/guide-js/JavaGuide'));
const PythonGuide = lazy(() => import('./programming-guide/guide-js/PythonGuide'));
const PhpGuide = lazy(() => import('./programming-guide/guide-js/PhpGuide'));
const JavaScriptGuide = lazy(() => import('./programming-guide/guide-js/JavaScriptGuide'));
const CppGuide = lazy(() => import('./programming-guide/guide-js/CppGuide'));
const CppInterview = lazy(() => import('./interview-questions/questions-js/CppInterview'));
const JavaInterview = lazy(() => import('./interview-questions/questions-js/JavaInterview'));
const JavaScriptInterview = lazy(() => import('./interview-questions/questions-js/JavaScriptInterview'));
const PhpInterview = lazy(() => import('./interview-questions/questions-js/PhpInterview'));
const PythonInterview = lazy(() => import('./interview-questions/questions-js/PythonInterview'));
const CppProjects = lazy(() => import('./build-projects/projects-js/CppProjects'));
const PhpProjects = lazy(() => import('./build-projects/projects-js/PhpProjects'));
const PythonProjects = lazy(() => import('./build-projects/projects-js/PythonProjects'));
const JavaProjects = lazy(() => import('./build-projects/projects-js/JavaProjects'));
const JavaScriptProjects = lazy(() => import('./build-projects/projects-js/JavaScriptProjects'));

class App extends Component {
  render() {
    return (
      <div className="App">
        <HeaderPages /> {/* Use the HeaderPages component */}
        <Route
          path="/"
          render={({ location }) => (
            location.pathname !== '/java-guide' &&
            location.pathname !== '/python-guide' &&
            location.pathname !== '/php-guide' &&
            location.pathname !== '/javascript-guide' &&
            location.pathname !== '/cpp-guide' &&
            location.pathname !== '/java-interview' &&
            location.pathname !== '/python-interview' &&
            location.pathname !== '/javascript-interview' &&
            location.pathname !== '/cpp-interview' &&
            location.pathname !== '/php-interview' && 
            location.pathname !== '/cpp-projects' &&
            location.pathname !== '/php-projects' &&
            location.pathname !== '/python-projects' &&
            location.pathname !== '/java-projects' &&
            location.pathname !== '/javascript-projects' && (
              <AdditionalContent />
            )
          )}
        />

        {/* Centered loading spinner while pages are loading */}
        <Suspense fallback={
          <div className="spinner-container">
            <FaSpinner className="spinner" />
          </div>
        }>
          <Switch>
            <Route exact path="/" render={() => (
              <Redirect to="/Home" />
            )} />
            <Route exact path='/Home' component={Customers} />
            <Route path='/customerdetails/:id' component={CustomerDetails} />
            <Route path='/java-guide' component={JavaGuide} />
            <Route path='/python-guide' component={PythonGuide} />
            <Route path='/php-guide' component={PhpGuide} />
            <Route path='/javascript-guide' component={JavaScriptGuide} />
            <Route path='/cpp-guide' component={CppGuide} />
            <Route path='/java-interview' component={JavaInterview} />
            <Route path='/python-interview' component={PythonInterview} />
            <Route path='/javascript-interview' component={JavaScriptInterview} />
            <Route path='/cpp-interview' component={CppInterview} />
            <Route path='/php-interview' component={PhpInterview} />
            <Route path='/cpp-projects' component={CppProjects} />
            <Route path='/php-projects' component={PhpProjects} />
            <Route path='/python-projects' component={PythonProjects} />
            <Route path='/java-projects' component={JavaProjects} />
            <Route path='/javascript-projects' component={JavaScriptProjects} />
          </Switch>
        </Suspense>

        <Footer />
      </div>
    );
  }
}
export default withRouter(App);
