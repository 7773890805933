import React, { useState } from 'react';
import './Footer.css';
import { MdEmail } from 'react-icons/md';
import { FaPhoneAlt, FaLaptopCode } from 'react-icons/fa';

function Footer() {
  const [formType, setFormType] = useState(null);

  const handleFormOpen = (type) => {
    setFormType(type);
  };

  const handleCloseForm = () => {
    setFormType(null);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section logo">
          <img src={require('./crunch logo.png')} alt="Crunch Coding Logo" className="footer-logo" />
        </div>

        <div className="footer-section links">
          <a href="#coding-help" onClick={() => handleFormOpen('coding-help')}>Coding Help</a>
          <a href="#contact-us" onClick={() => handleFormOpen('contact-us')}>Contact Us</a>
          <a href="#ebooks" onClick={() => window.open('https://www.amazon.com/JAVA-CHRONICLES-Expertise-Through-Challenges-ebook/dp/B0D6G7S5DX', '_blank')}>Ebooks</a>
        </div>

        <div className="footer-section newsletter">
           {/* <h3>
            <span className="mail-emoji" role="img" aria-label="email">📧</span> Subscribe to Our Newsletter 
          </h3>
          <input type="email" placeholder="Enter your e-mail address" />
          <button>Sign Up</button>*/}
        </div> 
      </div>

      {formType && (
        <div className="form-overlay">
          <div className="form-container">
            <button className="close-btn" onClick={handleCloseForm}>X</button>

            {formType === 'coding-help' && (
              <div>
                <h2><FaLaptopCode />Coding Help</h2>
                <p><MdEmail />Email Us:crunchcodingin@gmail.com</p>
                <p><MdEmail />Email Us: Oceansvalley@gmail.com</p>
              </div>
            )}

            {formType === 'contact-us' && (
              <div>
                <h2><FaPhoneAlt /> Contact Us</h2>
                <p><MdEmail />Email Us:crunchcodingin@gmail.com</p>
                <p><MdEmail />Email Us: Oceansvalley@gmail.com</p>
              </div>
            )}
          </div>
        </div>
      )}

    
    </footer>
  );
}

export default Footer;
